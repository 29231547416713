
@font-face {
    font-family: 'FuturaLT-Bold';
    src: url('./Fonts/FuturaLT-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'FuturaLT-Condensed';
    src: url('./Fonts/FuturaLT-Condensed.ttf') format('truetype');
}

@font-face {
    font-family: 'futura-lt-extra-bold';
    src: url('./Fonts/futura-lt-extra-bold.otf') format('opentype');
}

@font-face {
    font-family: 'FuturaLT-Heavy';
    src: url('./Fonts/FuturaLT-Heavy.ttf') format('truetype');
}

@font-face {
    font-family: 'FuturaLT-Light';
    src: url('./Fonts/FuturaLT-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'FuturaLT-Regular';
    src: url('./Fonts/FuturaLT.ttf') format('truetype');
}

body {
    font-family: 'FuturaLT-Regular', sans-serif !important;
}
